import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Surveying and Leveling`}</h1>
    <h2>{`Surveying & Leveling I & II Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVE9SanZRS1ktaWM"
      }}>{`Download: Surveying and Levelling by NN Basak 2nd Ed. 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PWHJhaUJuOGROOWc"
      }}>{`Download: Surveying and Levelling by NN Basak 2nd Ed. 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PS0pYWlJGVmU5aDQ"
      }}>{`Download: Surveying by S.K. Duggal Vol 1 Ch 2, 3, 5`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PWjd6eXppLVRsZFU"
      }}>{`Download: Surveying by S.K. Duggal Vol. 2 `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=19lAdJuvzQuuyMTTSmPWWDUGZB7BGKKSr"
      }}>{`Download: Engineering Surveying by W. Schofield and M. Breach 6th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1An9GeuFp-yR7LNBkCns6mKm6ouj3O1NH"
      }}>{`Download: Engineering Surveying by W. Schofield 5th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1UD0dk8IxANGy9bRmgVgJ-GaGUUtsh597"
      }}>{`Download: Fundamentals of surveying by S.K. Roy `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Fdb8OAb_R9JaLZj6QRmZOZqca0KWL5GJ"
      }}>{`Download: Triangulation and trilateration from Higher surveying by A.M. Chandra`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PMG84TTZkZHVKaFU"
      }}>{`Download: Surveying by A Bannister, S Raymond and R Baker 6th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1yeSojDLt0pm5iX3DLVrBMiCbyfyvLV-7"
      }}>{`Download: Surveying Vol II by B.C. Punmia, Ashok Kumar Jain, Arun Kumar Jain 15th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1m4ZehSL9TrmlNSilhnw0wgqgvjuhcIgU"
      }}>{`Download: Field Astronomy for Surveyors by G.G. Bennett and J.G. Freislich`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1YcZYBTB1lG3jKQ6Gyh58JJbmahz_YMjR"
      }}>{`Download: Surveying Problem solving with theory and objective type questions by Dr. A.M. Chandra`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=13jRnBbOOfU3QqWGqPurShLSUsMNjMzLi"
      }}>{`Download: Civil engineering surveying objective type for competitive exams1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1hTekerPaaALBj6oG7Xqksdr1pJOy3Jge"
      }}>{`Download: Civil engineering surveying objective type for competitive exams2`}</a></p>
    <h2>{`Surveying & Leveling mix topics`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/AZIMUTH-DETERMINATION.pdf"
      }}>{`AZIMUTH DETERMINATION`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/field-astronomy.pdf"
      }}>{`field-astronomy`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/fundamentals_of_surveying.pdf"
      }}>{`fundamentals_of_surveying`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/curves.pdf"
      }}>{`curves`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Geometric-Design.ppt"
      }}>{`Geometric Design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/A-new-Method-for-Determining-Linear-Thermal-Expansion.pdf"
      }}>{`A new Method for Determining Linear Thermal Expansion`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/introductory-lec-GIS-RS.pptx"
      }}>{`introductory lec GIS & RS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/precise-levelling.pdf"
      }}>{`precise levelling`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Vertical_Curves.pps"
      }}>{`Vertical_Curves`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lecture-no-2-Tape-measurements.pptx"
      }}>{`Lecture no 2 Tape measurements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Lecture-no-3-EDM.pptx"
      }}>{`Lecture no 3 EDM`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      